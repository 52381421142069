// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-biblestudy-channel-js": () => import("./../../../src/pages/biblestudy/channel.js" /* webpackChunkName: "component---src-pages-biblestudy-channel-js" */),
  "component---src-pages-biblestudy-js": () => import("./../../../src/pages/biblestudy.js" /* webpackChunkName: "component---src-pages-biblestudy-js" */),
  "component---src-pages-biblestudy-prophecy-js": () => import("./../../../src/pages/biblestudy/prophecy.js" /* webpackChunkName: "component---src-pages-biblestudy-prophecy-js" */),
  "component---src-pages-biblestudy-theword-js": () => import("./../../../src/pages/biblestudy/theword.js" /* webpackChunkName: "component---src-pages-biblestudy-theword-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-devotions-js": () => import("./../../../src/pages/devotions.js" /* webpackChunkName: "component---src-pages-devotions-js" */),
  "component---src-pages-diet-beverages-js": () => import("./../../../src/pages/diet/beverages.js" /* webpackChunkName: "component---src-pages-diet-beverages-js" */),
  "component---src-pages-diet-breakfast-js": () => import("./../../../src/pages/diet/breakfast.js" /* webpackChunkName: "component---src-pages-diet-breakfast-js" */),
  "component---src-pages-diet-dinner-js": () => import("./../../../src/pages/diet/dinner.js" /* webpackChunkName: "component---src-pages-diet-dinner-js" */),
  "component---src-pages-diet-js": () => import("./../../../src/pages/diet.js" /* webpackChunkName: "component---src-pages-diet-js" */),
  "component---src-pages-diet-lunch-js": () => import("./../../../src/pages/diet/lunch.js" /* webpackChunkName: "component---src-pages-diet-lunch-js" */),
  "component---src-pages-diet-salads-js": () => import("./../../../src/pages/diet/salads.js" /* webpackChunkName: "component---src-pages-diet-salads-js" */),
  "component---src-pages-diet-snacks-js": () => import("./../../../src/pages/diet/snacks.js" /* webpackChunkName: "component---src-pages-diet-snacks-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-family-js": () => import("./../../../src/pages/family.js" /* webpackChunkName: "component---src-pages-family-js" */),
  "component---src-pages-family-kids-js": () => import("./../../../src/pages/family/kids.js" /* webpackChunkName: "component---src-pages-family-kids-js" */),
  "component---src-pages-family-mw-js": () => import("./../../../src/pages/family/mw.js" /* webpackChunkName: "component---src-pages-family-mw-js" */),
  "component---src-pages-family-youth-js": () => import("./../../../src/pages/family/youth.js" /* webpackChunkName: "component---src-pages-family-youth-js" */),
  "component---src-pages-health-fitness-js": () => import("./../../../src/pages/health_fitness.js" /* webpackChunkName: "component---src-pages-health-fitness-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lifestyles-country-js": () => import("./../../../src/pages/lifestyles/country.js" /* webpackChunkName: "component---src-pages-lifestyles-country-js" */),
  "component---src-pages-lifestyles-faithwalks-js": () => import("./../../../src/pages/lifestyles/faithwalks.js" /* webpackChunkName: "component---src-pages-lifestyles-faithwalks-js" */),
  "component---src-pages-lifestyles-js": () => import("./../../../src/pages/lifestyles.js" /* webpackChunkName: "component---src-pages-lifestyles-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-testpage-2-js": () => import("./../../../src/pages/testpage2.js" /* webpackChunkName: "component---src-pages-testpage-2-js" */),
  "component---src-pages-testpage-3-js": () => import("./../../../src/pages/testpage3.js" /* webpackChunkName: "component---src-pages-testpage-3-js" */),
  "component---src-pages-testpage-js": () => import("./../../../src/pages/testpage.js" /* webpackChunkName: "component---src-pages-testpage-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-worldtoday-js": () => import("./../../../src/pages/worldtoday.js" /* webpackChunkName: "component---src-pages-worldtoday-js" */),
  "component---src-templates-diet-template-js": () => import("./../../../src/templates/diet-template.js" /* webpackChunkName: "component---src-templates-diet-template-js" */),
  "component---src-templates-health-fitness-template-js": () => import("./../../../src/templates/health_fitness-template.js" /* webpackChunkName: "component---src-templates-health-fitness-template-js" */),
  "component---src-templates-lifestyles-template-js": () => import("./../../../src/templates/lifestyles-template.js" /* webpackChunkName: "component---src-templates-lifestyles-template-js" */),
  "component---src-templates-word-js": () => import("./../../../src/templates/word.js" /* webpackChunkName: "component---src-templates-word-js" */),
  "component---src-templates-worldtoday-template-js": () => import("./../../../src/templates/worldtoday-template.js" /* webpackChunkName: "component---src-templates-worldtoday-template-js" */)
}

